export const introData = [
    { value1: 260, value2: 9000 },
    { value1: 60, value2: 800 },
    { value1: 20, value2: 620908 },
    { value1: 200, value2: 5000 },
]

export const socialData1 = [
    { title: '健康服务进社区，专业细致暖人心', img: require('@/assets/about_us/society1.png'), maskContent: '老白怡养药房是老白健康的社区健康管理服务中心，以积极应对人口老龄化挑战、助力我国多层次医疗保障服务体系建设为服务目标，聚焦慢病，扎根社区，通过整合优势资源为居民提供安全、便捷的“互联网+医疗健康”便民服务，极大的方便了居民就医用药。' },
    { title: '关爱特殊群体，播撒“爱与责任”', img: require('@/assets/about_us/society2.png'), maskContent: '老白健康为退役军人、残障人士、山区儿童、留守老人等特殊群体提供数字化健康管理服务。为甘肃、江苏、四川等9个省的退役军人捐赠了医疗爱心卡，并联合山西省社会救助基金会合作“山西社会救助诊疗卡”产品。截至目前，已累计服务了1.65万特殊群体家庭，帮助节省了近100万元购药支出。' },
]

export const processImgs = [
    require('@/assets/about_us/dev1.png'),
    require('@/assets/about_us/dev2.png'),
    require('@/assets/about_us/dev3.png'),
    require('@/assets/about_us/dev4.png'),
    require('@/assets/about_us/dev5.png'),
    require('@/assets/about_us/dev6.png'),
]

export const socialData2 = [
    { title: '勇担抗疫责任，保障居民用药', img: require('@/assets/about_us/society3.png'), maskContent: '老白健康勇担抗疫责任，积极贡献企业力量，疫情期间向重庆市渝中区石油路小学捐赠了新型冠状病毒抗原检测试剂2496盒，助力石小做好新冠病毒感染防控工作。' },
    { title: '聚焦新市民保障，开创服务新格局', img: require('@/assets/about_us/society4.png'), maskContent: '老白健康与保险公司一起在河南部分地区推出的“公众责任+员工雇主/团意+食责险”一揽子保险计划，累计为超10000家餐饮企业及员工提供服务，帮助节省数百万医疗费用支出。此外，老白健康在云南和内蒙针对快递员群体推出了互联网门诊险，在新市民健康保障上贡献了力量。' },
    { title: '助力乡村振兴，浇灌文化之花', img: require('@/assets/about_us/society5.png'), maskContent: '老白健康在浙江推出“惠村保”保险产品，为超千万农村居民提供健康服务和风险保障，打通了农村居民健康保障“最后一公里，在乡村振兴建设中贡献了老白力量。' },
]

export const advantageData = [
    {img:require('@/assets/about_us/adv1.png'),title:'自有互联网医院',content:'老白健康自有互联网医院，医疗团队由军医专家、三甲医院资深专家、医学博士组成，入驻专家医生超过300名，提供健康改善、健康干预、社群服务、健康直播等服务'},
    {img:require('@/assets/about_us/adv2.png'),title:'商保集采，药品广覆盖',content:'老白健康携手药企、联合保司，通过带量集采的模式为用户提供“厂家-患者”端对端药品服务，药品目录覆盖15个科室、355+种疾病、各种常见用药类别'},
    {img:require('@/assets/about_us/adv3.png'),title:'五大仓储物流中心，保障发货',content:'老白健康在重庆、江西、安徽、新疆、内蒙古建立了五大仓储物流中心，辐射全国，就近推单，与行业内速度最快的京东快递合作，最快次日达'},
    {img:require('@/assets/about_us/adv4.png'),title:'自研系统，定制集成',content:'老白健康自主搭建健康管理平台、互联网医疗购药平台、商保集采平台，自研PBM系统，能够帮助客户实现产品定制、数据可视化和系统集成等'},
    {img:require('@/assets/about_us/adv5.png'),title:'队伍覆盖全国，协同推动更高效',content:'9个总部部门+河北山西分公司、江苏分公司、广东分公司、川渝分公司、北京分公司、甘肃分公司、江西分公司、福建分公司、山东河南分公司、内蒙古分公司、新疆分公司、浙江上海分公司、陕西分公司、湖北安徽分公司、辽宁吉林分公司、贵州云南分公司、广西分公司、湖南分公司'},
    {img:require('@/assets/about_us/adv6.png'),title:'创新研究，产学合作',content:'老白健康注重科研创新，与浙江大学、中央财经大学共同启动“数字化医疗药品福利和普惠医疗保险发展”等研究课题项目'},
    {img:require('@/assets/about_us/adv7.png'),title:'健康管理，增值服务',content:'老白健康为用户提供一站式增值服务，包括就医绿通、陪诊陪护、CAR-T权益、心理咨询、疫苗接种、全球找药、牙科口腔、体检自测、眼科套餐、基因检测等'},
]